<template>
    <div class="loadingWrap">
      <div class="load" v-if="isPay">
        <div class="loadBox" ></div>
        <p>支付中，请稍后。。。</p>
      </div>
      <div class="loadFinnsh"  v-if="!isPay">
        <div class="wxLog"></div>
        <div class="btn" style="margin-top:0.5rem;margin-bottom:0.15rem" @click="rePay()">继续支付</div>
        <div class="btn" @click="backShop()">返回商户</div>
      </div>
    </div>
</template>
<script>
import "@/utils/jweixin.js";
export default {
  data(){
    return{
      bizId:'',
      sceneNo:'',
      skuId:'',
      spuId:'',
      wxBackResult:{},
      isPay:true
    }
  },
  watch:{

  },
  components: {
   
  },
   mounted() {
      this.bizId = sessionStorage.getItem('orderBizId');
      this.sceneNo = sessionStorage.getItem('sceneNo');
      this.skuId = sessionStorage.getItem('skuId');
      this.spuId = sessionStorage.getItem('spuId');
      console.log(this.bizId+'=='+this.skuId+'===='+this.spuId)
      if(this.bizId&&this.skuId&&this.spuId){
         this.getOpenId();
      }else{
          this.$router.push({
            path: "/h/scm/mall/hotSale",
            query: {
             scence: this.sceneNo
             }
          }); 
      }
   },
   destroyed() {
      sessionStorage.removeItem("orderBizId");
      sessionStorage.removeItem('skuId');
      sessionStorage.removeItem('spuId');

  },
  methods:{
    rePay(){
      this.getOpenId();
    },
    backShop(){
      this.$router.push({
            path: "/h/scm/mall/goodsDetail",
            query: {
             sceneNo: this.sceneNo,
             spuId: this.spuId
            }
      }); 
    },
    getOpenId(){
      const {code,state} = this.$route.query;
      console.log('授权回调页面')
      console.log(code)
      console.log(state)
      // var url = '/api/scm/wechatPay/getOpenId?code=011xpj000PHjIM1SAF0000PgmP3xpj08'
      var url = '/api/scm/wechatPay/getOpenId?code='+code+'&state='+state;
      this.request.post(url,{code:code,state:state}).then(res =>{
        // 
        // 
        console.log(res)
        if(res){
          this.orderPay(res);
        }
      })
    },
    orderPay(openid){
      let url = `${window.location.origin}/h/scm/order/payCheck?bizId=${this.bizId}&payType=7&openid=${openid}&skuId=${this.skuId}&sceneNo=${this.sceneNo}`,
        payBizId = this.bizId + String(new Date().getTime()).substr(-4, 4);
      this.request
        .postFrom(
          `/api/scm/pay/order?bizId=${this.bizId}&payType=7&payBizId=${payBizId}&openid=${openid}&returnUrl=${encodeURIComponent(url)}`,
          {}
        )
        .then((res) => {
          if (res) {
            console.log(res)
            this.wxBackResult = res;
            this.callPay();
          } else {
            if (!!res)
              this.$toast({
                message: "支付失败，请稍候重试",
                position: "bottom"
              });
          }
        });
    },
    callPay(){
      if (typeof WeixinJSBridge == "undefined"){
        if(document.addEventListener ){
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady, false);
        }else if(document.attachEvent){
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady);
          document.attachEvent('onWeixinJSBridgeReady',this.onBridgeReady);
        }
      }else{
        this.onBridgeReady();
      }
    },
    onBridgeReady(){
      var that = this;
      const {appId,timeStamp,nonceStr,signType,paySign,prepay_id} = this.wxBackResult;
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
        "appId" : appId,           //公e众号名称，由商户传入
        "timeStamp":timeStamp,    //时间戳，自1970年以来的秒数
        "nonceStr" :nonceStr,     //随机串
        "package" : prepay_id,      //预支付ID参数
        "signType" :signType,     //微信签名方式:
        "paySign" : paySign        //微信签名
      },
      function(res){
        console.log(res)
        if(res.err_msg == "get_brand_wcpay_request:ok" ) {            
        that.$router.push({
            path: "/h/scm/order/orderList",
            query: {
             sceneNo: this.sceneNo
        }
      });
        }else if(res.err_msg == "get_brand_wcpay_request:cancel" ){
          that.isPay = false;
        //  $("#confirm_div").show()
        }else{
          alert(JSON.stringify(res));
          that.isPay = false;
                            // window.location.href="";
        }
     });
    }

  }
}
</script>
<style lang="scss">
.loadingWrap{
  width: 100vw;
  height: 90vh;
  overflow: hidden;
  .load{
    width: 100%;
    height: 50vh;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .loadBox{
      width: 1rem;
      height: 1rem;
      background: url('~@/assets/order/loading.gif') no-repeat;
      background-size: 100% 100%;
    }
    p{
      margin-top: 0.1rem;
      font-size: 0.24rem;
      color: blue;
    }
  }
  .loadFinnsh{
    width: 100%;
    height: 50vh;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .wxLog{
      width: 52px;
      height: 52px;
      background: url('~@/assets/icons/wx.png') no-repeat;
      background-size: 100% 100%;
    }
    .btn{
      width: 5rem;
      height: 0.65rem;
      line-height: 0.65rem;
      text-align: center;
      color: #fff;
      border-radius: 0.15rem;
      font-size: 0.21rem;
      background: cornflowerblue;
    }
    .btn:nth-of-type(1){
      margin:0.15rem 0;
    }
  }
}
</style>
